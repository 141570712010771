a {
    text-decoration: none;
    color: white;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: .9rem 0 0.9rem 0;
    position: fixed;
    right: 0;
    // background-color: #101518;
    background-color: #0e1212;
    // background-color: rgb(28, 30, 33);
    z-index: 1;
    width: 100%;
    top: 0;
}

nav span {
    margin-right: 20px;
}

nav span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.active {
    color: #22DDD2;
    text-decoration: underline;
    text-decoration-color: #22DDD2;
}

#experiences {
    color: inherit;
}

#extracurriculars {
    color: inherit;
}

#projects {
    color: inherit;
}