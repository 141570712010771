.home {
    display: flex;
    flex-direction: row;
    padding: 0;
    padding-top: 0;
}

.home-header {
    padding: 5% 7.5%;
    padding-top: 0;
    padding-bottom: 0;
}

.profile {
    width: 40%;
    border-right: 1px solid #f1f2f3;
    background-color: #252734;
    z-index: 100;
}

.profile-container {
    top:0;
    bottom:0;
    padding: 15% 8%;
}

#profile-section {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 10;
}

#profile-section div div h2 {
    margin-bottom: 0;
}

#profile-section div div p {
    margin: 0;
    opacity: 0.4;
}

#profile-image {
    width: 148px;
    height: 148px;
    border-radius: 100px;
}

#profile-picture-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

#profile-picture-container div {
    margin-left: 20px;
}

.main-container {
    width: 65%;
    padding-top: 5%;
}

#main-section {
    padding: 0 7% 20% 7%;
}

.vertical-line {
    height: 100vh;
    border-left: 2px solid #682ae9;
    margin-left: 20px;
    position: sticky;
    top: 0;
}

.title-container {
    margin-bottom: 20px;
}

.title-container p {
    margin: 0;
}

.title-container div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title-container div :nth-child(1) {
    font-weight: 500;
}

.title-container div :nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white
}

.title-container :nth-child(2) {
    // color: rgba(255, 255, 255, 0.4) 
    color: #2E73EA;
}

.title-container div p {
    display: flex;
    flex-direction: row;
}

.arrow-container {
    display: flex;
    justify-content: center;
}

.arrow-icon {
    width: 1.8rem;
    height: 1.8rem;
    animation: updown 2.5s ease-in-out infinite;
    color: #2E73EA;
}

.arrow-icon:hover {
    cursor: pointer;
}

@keyframes updown {
    0% {
      transform: translateY(-6px);
    }
  
    30% {
      transform: translateY(6px);
    }
  
    100% {
      transform: translateY(-6px);
    }
  }

.work-experiences-container {
    margin-bottom: 75px;
}

.work-icon {
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 5px;
    margin-top: 2px;
    margin-left: 10px;
    color: #22DDD2;
}

.work-icon path {
    stroke-width: -1px;
}

.work-icon:hover {
    transition: 0.4s ease;
    font-weight: bolder !important;
    -webkit-animation: shake 1s ease infinite;
    -moz-animation: shake 1s ease infinite;
    animation: shake 1s ease infinite;
    // color: #22DD2;
}


.testimonial {
    color: #FFDF00;
}

p {
    font-size: 1rem;
}

// carousel
  
.container {
    margin: 0 auto;
    width: 250px;
    height: 250px;
    position: relative;
    perspective: 1000px;
}
  
.carousel {
    height: 100%;
    width: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 1s;
}
  
.item {
    display: block;
    position: absolute;
    background: #000;
    width: 250px;
    height: 250px;
    line-height: 200px;
    font-size: 5em;
    text-align: center;
    color: #FFF;
    opacity: 0.95;
    border-radius: 10px;
}

.item:hover .project-description {
    opacity: 1;
}

.item:hover {
    cursor: pointer;
}

.item img {
    width: 100%;
    height: 100%;
}
  
.a {
    transform: rotateY(0deg) translateZ(250px);
}

.b {
    transform: rotateY(60deg) translateZ(250px);
    background: #0072bc;
}
.c {
    transform: rotateY(120deg) translateZ(250px);
    background: #39b54a;
}
.d {
    transform: rotateY(180deg) translateZ(250px);
    background: black;
}
.e {
    transform: rotateY(240deg) translateZ(250px);
    background: #630460;
} 
.f {
    transform: rotateY(300deg) translateZ(250px);
    background: #8c6239;
}
  
.next, .prev {
    cursor: pointer;
    border-radius: 5px;
    transition: box-shadow 0.1s, top 0.1s;
}
.next:hover, .prev:hover { color: #2E73EA; }
.next:active, .prev:active {
    top: 104px;
    color: #2E73EA;
    // box-shadow: 0 1px 0 #999;
}

.project-description {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    // background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    transition: 1s ease;
    opacity:0;
    color: white;
    font-size: 1rem;
    text-align: center;
    line-height: 1;
}

.project-description h3 {
    word-wrap: break-word;
}

.project-description p {
    word-wrap: break-word;
    font-size: 0.5rem;
    z-index: 100;
    line-height: 1;
    text-align: left;
    margin-left: 5%;
    margin-right: 5%;
}

.project-description:hover {
    display: block;
    position: absolute;
    visibility: visible;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.icon {
    display: flex;
    align-self: center;
    color: white;
    font-size: 4rem;
}

.projects-carousel-container {
    display: flex;
    flex-direction: row;
    padding: 30px;
    animation: fadeIn ease 2s;
    margin-bottom: 150px;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
.contact-container {
    background-color: #2E73EA;
    position: fixed;
    height: 80px;
    left: 0;
    padding: 0 40px;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
    bottom: 10%;
    z-index: 100;
}

.contact-container p {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 900;
}

.contact-icon {
    width: 2rem;
    height: 2rem;
    border-radius: 5px;
    margin-right: 15px;
}

.contact-icon:hover {
    transition: 0.4s ease;
    font-weight: bolder !important;
    -webkit-animation: shake 1s ease infinite;
    -moz-animation: shake 1s ease infinite;
    animation: shake 1s ease infinite;
}

// .gmail {
//     left: 20px;
// }

// .github {
//     left: 60px;
// }

// .linkedin {
//     left: 100px;
// }

// .facebook {
//     left: 140px;
// }

// .instagram {
//     left: 180px;
// }

@keyframes shake {
    0% {
        transform: translateX(0px) rotate(0deg);
    }

    5% {
        transform: translateX(-4px) rotate(-4deg);
    }

    10% {
        transform: translateX(-2px) rotate(-2deg);
    }

    15% {
        transform: translateX(4px) rotate(4deg);
    }

    20% {
        transform: translateX(2px) rotate(2deg);
    }

    25% {
        transform: translateX(0px) rotate(0deg);
    }
    30% {
        transform: translateX(-4px) rotate(-4deg);
    }

    35% {
        transform: translateX(-2px) rotate(-2deg);
    }

    40% {
        transform: translateX(4px) rotate(4deg);
    }

    45% {
        transform: translateX(2px) rotate(2deg);
    }

    50% {
        transform: translateX(0px) rotate(0deg);
    }
    100% {
        transform: translateX(0px) rotate(0deg);
    }

}

.projects.section h2 {
    margin-bottom: 100px;
    color: #22DDD2;
}

// .projects.section {
//     // height: 78vh;
// }

#profile-picture-container div h1 {
    margin-bottom: 0;
    color: #2E73EA;
}

h3 {
    margin: 0;
}

.section {
    margin-bottom: 100px;
}

.resume-experience {
    // border-bottom: 1px solid #682ae9;
    transition: transform 3s, opacity 3s;
    transform: translateY(25px);
    opacity: 0;
  
    &.visible {
      transform: translateY(0);
      opacity: 1;
    }
    
    margin-bottom: 40px;
}

.skills-section h3{
    font-size: 1.125rem;
    font-weight: normal;
}

.skill-row {
    width: 100%;
    background-color: lightgray;
    height: 15px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
}

.skill-row-percent-python {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 10px;
    width: 80%;
    // background: #65ab02;
    background: #8C15E9;
    &.visible {
        animation: fill-python 1.5s ease-in-out;
    }
}

@keyframes fill-python {
    from {
      width: 0;
    }
  
    to {
      width: 80%;
    }
}

.skill-row-percent-java {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 10px;
    width: 70%;
    background: #58e6c4;
    &.visible {
        animation: fill-java 1.5s ease-in-out;
    }
}

@keyframes fill-java {
    from {
      width: 0;
    }
  
    to {
      width: 70%;
    }
}

.skill-row-percent-web {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 10px;
    width: 75%;
    background: #65ab02;
    &.visible {
        animation: fill-web 1.5s ease-in-out;
    }
}

@keyframes fill-web {
    from {
      width: 0;
    }
  
    to {
      width: 75%;
    }
}

.skill-row-percent-ruby {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 10px;
    width: 65%;
    background: #d63c3c;
    &.visible {
        animation: fill-ruby 1.5s ease-in-out;
    }
}

@keyframes fill-ruby {
    from {
      width: 0;
    }
  
    to {
      width: 65%;
    }
}