// adapted from https://codepen.io/P1N2O/pen/pyBNzX
// took the gradient and color styling
body, html {
    // font-family: "Roboto Mono";
	font-family: "Gothic A1";
	font-family: "Titillium Web";
	font-size: 16px;
	color: white;
	// color: #682ae9; // purple
	// background-color: #101518; // charcoal
	background-color: #0e1212;
	// background-color: #0a192f; // navy 
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.logo {
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
}

.file {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	animation: fadeIn ease 1.3s;
}

.document {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.page {
	width: 40%;
}


body {
	margin: 0;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

h1 {
	// color: #682ae9;
	color: #22DDD2;
	font-size: 3.75rem;
	margin: 0;
}

h2 {
	// color: #682ae9;
	color: #22DDD2;
	font-size: 2rem;
	font-weight: bolder;
}

p {
	font-size: 1.25rem;
}

.react-pdf__Page__annotations.annotationLayer {
	height: 0
}

.resume-page {
	display: flex;
	justify-content: center;
}

.resume-page img {
	margin-top: 5%;
}